<template>
  <div class="tabs is-toggle has-text-weight-semibold">
    <ul>
      <li :class="{ 'is-active': tab === 'actualCazanaCoded' }">
        <a
          @click="changeTab('actualCazanaCoded')"
          data-test-id="coded-options-tab"
        >
          <span>Spec Check Data</span>
        </a>
      </li>
      <li :class="{ 'is-active': tab === 'actual' }">
        <a @click="changeTab('actual')" data-test-id="actual-options-tab">
          <span>{{ make }} Actual Fit Data</span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'CompanionOptionsTabs',
  props: {
    tab: {
      type: String,
      required: true
    },
    make: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  methods: {
    changeTab(type) {
      this.$mxp.track(`companion_options_change_tab_${type}`)
      this.$emit('change-tab', type)
    }
  }
}
</script>
